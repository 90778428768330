<script>
export default {
  name: 'Title',
  components: {
  },
  props: [
  ],
  methods: {
  },
};

/* eslint-disable */
</script>


<template lang="pug">
div.secondary--text.page-title.pb-2.mb-4
  span.font-size--title.font-weight-bold
    slot

</template>
